<script>
import appConfig from '@/app.config'

export default {
	page: {
		title: 'change language',
		meta: [{ name: 'description', content: appConfig.description }],
	},
	data() {
	return {
           language:'english',
		   color:'#f9aa4d' 
		}
	},
	methods: {

	}
}
</script>
<style scoped>
#account-settings{
  height:calc(100vh - 120px);
}
.changelanguage{
    display: flex;
    flex-direction: column;
}
.changelanguage button{
    display: flex;
    align-items: center;
    padding-top:12px;
    padding-bottom: 12px;
    padding-left: 12px;
    outline:none;
    border:none;
    background: transparent;
    border:5px;
}
.changelanguage button:hover{
    background: #fffaf4;
	transition: all 0.5s;
}
.active{
	background: #fffaf4!important;
}
.changelanguage button p{
    margin-bottom:0px!important;
}
.__text{
	width:100%;
	display: flex;
	justify-content:space-between;
	align-items:center;
	padding-left:12px;
}
.feather__icon{
	color:#f9aa4d!important;
    fill:rgba( #f9aa4d,0)!important;
}
.tick{
	transition: all 0.4s;
}
.profile_form{
	width:385px;
	margin-left:3em;
}
@media screen and (max-width: 500px) {
	.profile_form{
		width:100%;;
		margin-left:0px;
	}	
}
</style>
<template>
    <div class="col-md-12">
		<div id="account-settings" class="mnotify-card">
			<div  id="side-indicators-content" class="mnotify-card-header">
				<div class="profile-settings-section-header">
					<profilesettingsBtn />
					<div class="profile-settings-sub-header">
						<h3 class="mnotify-card-header-text py-0 my-0">{{$t('dashboard.account_settings.change_language.title')}}</h3>
						<!-- <p class="profile-description">Change your current phone number to another</p> -->
					</div>
				</div>
			</div>
			<div class="mnotify-card-body">
				<div class="row profile-settings-form" style="margin-top:2em;">
					<div class="profile_form">
						<p class="profile-see-more pb-4">{{$t('dashboard.account_settings.change_language.select')}}please select your preferred language</p>
						<div class="profile-inner">
							<div class="changelanguage">
                            <button @click="language = 'english'" :class="[language === 'english' ? 'active' : '']">
                                <img  height="16" width="21" src="@/assets/english.png" alt="english">	
                                <p class="__text ml-3"> {{$t('dashboard.account_settings.change_language.english')}} <span class="tick" v-if="language === 'english'"><img height="40" src="../../../assets/tick.svg" alt=""> </span> </p>
                            </button>
                            <button @click="language = 'french'" :class="[language === 'french' ? 'active' : '']">
                                <img height="16" width="21" class="mr-3" src="@/assets/french.png" alt="french">
                                <p class="__text">{{$t('dashboard.account_settings.change_language.french')}} <span class="tick" v-if="language === 'french'"><img height="40" src="../../../assets/tick.svg" alt=""> </span></p>
                            </button>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
